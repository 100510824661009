import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _188b2ce5 = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _53f161d0 = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _4e9cce58 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _d0313b2e = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _4ac90950 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _d5da4fee = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _70311f5e = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _4013ac73 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _417df134 = () => interopDefault(import('../pages/financial-metrics/index.vue' /* webpackChunkName: "pages/financial-metrics/index" */))
const _6dc36bea = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _083c9fe7 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _6027cf48 = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _cc3abbfa = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _15117d5b = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _13dcb934 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _1f161222 = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _1cb3092a = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _d26da06c = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _142f8358 = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _7a3ce953 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _67556694 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _1ef65d4c = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _228fad5a = () => interopDefault(import('../pages/operator-details/index.vue' /* webpackChunkName: "pages/operator-details/index" */))
const _e8f0c1c8 = () => interopDefault(import('../pages/operator-traffic-dashboard/index.vue' /* webpackChunkName: "pages/operator-traffic-dashboard/index" */))
const _5d9db875 = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _26a5c48f = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _11d4691b = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _8c6e982a = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _763f79e1 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _2d2090e4 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _153f9d54 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _ad101e18 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _77272b84 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _1195cf08 = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _34af925c = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _626edb6a = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _8e8438b8 = () => interopDefault(import('../pages/dashboard/productivity-metrics/index.vue' /* webpackChunkName: "pages/dashboard/productivity-metrics/index" */))
const _639c09f0 = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _bc61403c = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _0fe44958 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _17520920 = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _80f6855c = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _2017d73d = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _b1fbd79a = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _c8b06420 = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _1c65c9ca = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _35ab5522 = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _5a30e1ab = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _2f8d5241 = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _95819cbe = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _64e6f61a = () => interopDefault(import('../pages/profiles/request-update/request-update-mixin.js' /* webpackChunkName: "pages/profiles/request-update/request-update-mixin" */))
const _09793c8b = () => interopDefault(import('../pages/profiles/request-update/_profile.vue' /* webpackChunkName: "pages/profiles/request-update/_profile" */))
const _3aadc38d = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _0ae5e2ae = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _016e196d = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _3c965df2 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _5cde038c = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _1ed2aab8 = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _7a63f354 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _6d59433f = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _36b59565 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _df030174 = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _8918d402 = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _7233c29d = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _3c3bd124 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _49bedcc2 = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _4b69da44 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _188b2ce5,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _53f161d0,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _4e9cce58,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _d0313b2e,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _4ac90950,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _d5da4fee,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _70311f5e,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _4013ac73,
    name: "feedback-funnel"
  }, {
    path: "/financial-metrics",
    component: _417df134,
    name: "financial-metrics"
  }, {
    path: "/ht-alerts",
    component: _6dc36bea,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _083c9fe7,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _6027cf48,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _cc3abbfa,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _15117d5b,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _13dcb934,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _1f161222,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _1cb3092a,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _d26da06c,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _142f8358,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _7a3ce953,
    name: "my-team"
  }, {
    path: "/news",
    component: _67556694,
    name: "news"
  }, {
    path: "/notifications",
    component: _1ef65d4c,
    name: "notifications"
  }, {
    path: "/operator-details",
    component: _228fad5a,
    name: "operator-details"
  }, {
    path: "/operator-traffic-dashboard",
    component: _e8f0c1c8,
    name: "operator-traffic-dashboard"
  }, {
    path: "/payment-updates",
    component: _5d9db875,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _26a5c48f,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _11d4691b,
    name: "profile"
  }, {
    path: "/profiles",
    component: _8c6e982a,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _763f79e1,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _2d2090e4,
    name: "settings"
  }, {
    path: "/stats",
    component: _153f9d54,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _ad101e18,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _77272b84,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _1195cf08,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _34af925c,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _626edb6a,
    name: "wink-replies"
  }, {
    path: "/dashboard/productivity-metrics",
    component: _8e8438b8,
    name: "dashboard-productivity-metrics"
  }, {
    path: "/manuals/new",
    component: _639c09f0,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _bc61403c,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _0fe44958,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _17520920,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _80f6855c,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _2017d73d,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _b1fbd79a,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _c8b06420,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _1c65c9ca,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _35ab5522,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _5a30e1ab,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _2f8d5241,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _95819cbe,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/profiles/request-update/request-update-mixin",
    component: _64e6f61a,
    name: "profiles-request-update-request-update-mixin"
  }, {
    path: "/profiles/request-update/:profile?",
    component: _09793c8b,
    name: "profiles-request-update-profile"
  }, {
    path: "/account-requests/:request?",
    component: _3aadc38d,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _0ae5e2ae,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _016e196d,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _3c965df2,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _5cde038c,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _1ed2aab8,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _7a63f354,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _6d59433f,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _36b59565,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _df030174,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _8918d402,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _7233c29d,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _3c3bd124,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _49bedcc2,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _4b69da44,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
